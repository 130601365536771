import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledImg = styled(Img)`
  position: relative;
  width: 100%;
  height: auto;
  background-color: red;

  @media (max-width: 576px) {
  }

  @media (max-width: 768px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 1200px) {
  }
`

const WbnSlide = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease;
  background-size: cover !important;
  background-position: center !important;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }

  .wbn-overlay-text {
    position: absolute;
    top: 0;
    z-index: 100;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 0 7.6rem;
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0;
    transition: all 2.2s;
    transform: translateY(0%);
    transition: all 400ms cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutExpo */

    @media (max-width: 1200px) {
    }

    .wbn-header {
      font-family: "Poppins", sans-serif;
      font-size: 3rem;
      color: #171717;
      font-weight: 300;
      line-height: 2rem;
      transition: all 1s;
      /* text-shadow: 2px 2px 0px #000000; */
      transform: scale(0.7);
      margin: 20px 0;

      @media (min-width: 576px) {
        font-size: 3rem;
        line-height: 3rem;
      }

      @media (min-width: 768px) {
        font-size: 4rem;
        line-height: 4rem;
      }

      @media (min-width: 992px) {
        font-size: 5rem;
        line-height: 5rem;
      }

      @media (min-width: 1200px) {
        font-size: 5.2rem;
        line-height: 6rem;
      }
    }

    .wbn-text {
      font-family: Arial, Helvetica, sans-serif;
      text-align: left;
      font-size: 0.8rem;
      line-height: 20px;
      font-weight: 300;
      margin: 0 0 40px 0;
      color: #66686e;
      max-width: 50%;
      transition: all 1s;
      opacity: 0;
      /* text-shadow: 2px 2px 0px #000000; */
      transform: translateY(50%);

      @media (min-width: 576px) {
        line-height: 25px;
        font-size: 0.9rem;
        max-width: 30%;
      }

      @media (min-width: 768px) {
        font-size: 0.9rem;
      }

      @media (min-width: 992px) {
        font-size: 1rem;
        line-height: 30px;
      }

      @media (min-width: 1200px) {
        font-size: 1rem;
      }
    }
    button {
      position: relative;
      text-decoration: none;
      color: #171717;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.78rem;
      white-space: nowrap;
      border: 3px solid #171717;
      border-radius: 0.5rem;
      padding: 0.8rem 0.99rem 0.85rem;
      width: auto;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#41aa0a),
          to(#0797b9)
        );
        background: linear-gradient(to right, #41aa0a, #0797b9);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        text-shadow: none;
        border-color: #0797b9;
      }
    }
  }
`

const Slide = ({ slide, active }) => (
  <WbnSlide className={active ? "active" : ""}>
    <StyledImg fixed={slide.featured_media.localFile.childImageSharp.fixed} />
    <div className="wbn-overlay-text">
      <h1 className="wbn-header">{slide.acf.slider_header}</h1>
      <p className="wbn-text">{slide.acf.slider_text}</p>
      <Link to={slide.acf.slider_button_link}>
        <button type="button">{slide.acf.slider_button_text}</button>
      </Link>
    </div>
  </WbnSlide>
)

export default Slide
