import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useInterval } from "./useInterval"
import styled from "styled-components"

import Slide from "./Slide"

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  position: relative;
  align-items: center;

  .active {
    z-index: 10;
    opacity: 1;
  }

  .active div {
    opacity: 1;
  }

  .active div .wbn-text {
    opacity: 1;
    transform: translateY(0%);
  }

  .active div .wbn-header {
    opacity: 1;
    transform: scale(1);
  }

  @media (min-width: 768px) {
    height: 600px;
  }

  @media (min-width: 992px) {
    height: calc(100vh - 28px);
  }

  @media (min-width: 1200px) {
    height: calc(100vh - 32px);
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
  top: 0;
  height: 100%;
  padding: 0 20px 0 20px;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  @media (max-width: 768px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 1200px) {
  }
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 20px;
  opacity: 0.7;
  border: 0;
  outline: none;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  transition: all 0.2s;

  :hover {
    transform: scale(1.1);
    opacity: 0.8;
    cursor: pointer;
  }
`

const HeroSlider = () => {
  const {
    slides: { edges: slides },
  } = useStaticQuery(graphql`
    query slider {
      slides: allWordpressWpSlider {
        edges {
          node {
            id
            featured_media {
              localFile {
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fixed(quality: 80, width: 4000) {
                    base64
                    width
                    height
                    src
                    srcSet
                  }
                }
              }
            }
            acf {
              slider_pre_header
              slider_header
              slider_text
              slider_button_text
              slider_button_link
            }
          }
        }
      }
    }
  `)

  const [state, setState] = useState({
    slides,
    activeIndex: 0,
    autoSlide: true,
    interval: 10000,
  })

  // By Dan Abramov overreacted.io
  useInterval(
    () => {
      setState(prev => ({
        ...prev,
        activeIndex: (prev.activeIndex + 1) % prev.slides.length,
      }))
    },
    state.autoSlide ? state.interval : null
  )

  const handlePrevClick = () => {
    setState(prev => ({
      ...prev,
      autoSlide: false,
      activeIndex:
        (prev.activeIndex - 1 + prev.slides.length) % prev.slides.length,
    }))
  }

  const handleNextClick = () => {
    setState(prev => ({
      ...prev,
      autoSlide: false,
      activeIndex: (prev.activeIndex + 1) % prev.slides.length,
    }))
  }

  return (
    <SliderWrapper>
      {slides.map((slide, i) => (
        <Slide
          key={slide.node.id}
          slide={slide.node}
          active={state.activeIndex === i}
        />
      ))}
      <ButtonWrapper style={{ position: "absolute", left: 0 }}>
        <Button onClick={handlePrevClick}>&#9664;</Button>
      </ButtonWrapper>
      <ButtonWrapper style={{ position: "absolute", right: 0 }}>
        <Button onClick={handleNextClick}>&#9654;</Button>
      </ButtonWrapper>
    </SliderWrapper>
  )
}

export default HeroSlider
