import React from "react"

import Layout from "../components/layout"
import HeroSlider from "../components/HeroSlider"
// import CTAImages from '../components/index/CTAImages';
// import LatestTrend from '../components/index/LatestTrend';
// import Citat from '../components/index/Citat';
// import About from '../components/index/About';

const HomePage = () => (
  <Layout>
    <HeroSlider />
    {/* <CTAImages />
    <LatestTrend />
    <Citat />
    <About /> */}
  </Layout>
)

export default HomePage
